

const gameSeed = [{
    title: "Tetris Effect",
    system_type: "PS4",
    developer: "Enhance Games",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/3059453-image.jpeg",
    description: "Tetris for the PS4/PSVR by Tetsuya Mizuguchi, creator of REZ and Lumines.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    owned: true,
    rating: 9.5,
    price: 40,
    year_released: 2018,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:100
},
{
    title: "BioShock Infinite",
    system_type: "PS3",
    developer: "Irrational Games",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/2379677-bioshockinfinite_boxart.jpg",
    description: "The third game in the BioShock series leaves the bottom of the sea behind for an entirely new setting - the floating city of Columbia, circa 1912. Come to retrieve a girl named Elizabeth, ex-detective Booker DeWitt finds more in store for him there than he could ever imagine.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    rating: 10,
    price: 10,
    year_released: 2013,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:101
},
{
    title: "Metroid: Zero Mission",
    system_type: "GBA",
    developer: "Nintendo",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/2617564-mzm.jpg",
    description: "A GBA Metroid game and remake of the original Metroid for the NES. It also depicts what happens immediately after the self destruct sequence by Mother Brain.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    rating: 9.5,
    price: 25,
    year_released: 2004,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:102
},{
    title: "Tetris Effect",
    system_type: "PS4",
    developer: "Enhance Games",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/3059453-image.jpeg",
    description: "Tetris for the PS4/PSVR by Tetsuya Mizuguchi, creator of REZ and Lumines.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    owned: true,
    rating: 9.5,
    price: 40,
    year_released: 2018,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:103
},
{
    title: "BioShock Infinite",
    system_type: "XONE",
    developer: "Irrational Games",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/2379677-bioshockinfinite_boxart.jpg",
    description: "The third game in the BioShock series leaves the bottom of the sea behind for an entirely new setting - the floating city of Columbia, circa 1912. Come to retrieve a girl named Elizabeth, ex-detective Booker DeWitt finds more in store for him there than he could ever imagine.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    rating: 10,
    price: 10,
    year_released: 2013,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:104
},
{
    title: "Metroid: Zero Mission",
    system_type: "GBA",
    developer: "Nintendo",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/2617564-mzm.jpg",
    description: "A GBA Metroid game and remake of the original Metroid for the NES. It also depicts what happens immediately after the self destruct sequence by Mother Brain.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    rating: 9.5,
    price: 25,
    year_released: 2004,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:105
},{
    title: "Tetris Effect",
    system_type: "PS4",
    developer: "Enhance Games",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/3059453-image.jpeg",
    description: "Tetris for the PS4/PSVR by Tetsuya Mizuguchi, creator of REZ and Lumines.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    owned: true,
    rating: 9.5,
    price: 40,
    year_released: 2018,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:106
},
{
    title: "Super Smash Bros",
    system_type: "NSW",
    developer: "Irrational Games",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/2379677-bioshockinfinite_boxart.jpg",
    description: "The third game in the BioShock series leaves the bottom of the sea behind for an entirely new setting - the floating city of Columbia, circa 1912. Come to retrieve a girl named Elizabeth, ex-detective Booker DeWitt finds more in store for him there than he could ever imagine.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    rating: 10,
    price: 10,
    year_released: 2013,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:107
},
{
    title: "Xenoblade X",
    system_type: "WiiU",
    developer: "Nintendo",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/2617564-mzm.jpg",
    description: "A GBA Metroid game and remake of the original Metroid for the NES. It also depicts what happens immediately after the self destruct sequence by Mother Brain.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    rating: 9.5,
    price: 25,
    year_released: 2004,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:108
},{
    title: "Tetris Effect",
    system_type: "PS4",
    developer: "Enhance Games",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/3059453-image.jpeg",
    description: "Tetris for the PS4/PSVR by Tetsuya Mizuguchi, creator of REZ and Lumines.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    owned: true,
    rating: 9.5,
    price: 40,
    year_released: 2018,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:109,
},
{
    title: "BioShock Infinite",
    system_type: "PS3",
    developer: "Irrational Games",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/2379677-bioshockinfinite_boxart.jpg",
    description: "The third game in the BioShock series leaves the bottom of the sea behind for an entirely new setting - the floating city of Columbia, circa 1912. Come to retrieve a girl named Elizabeth, ex-detective Booker DeWitt finds more in store for him there than he could ever imagine.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    rating: 10,
    price: 10,
    year_released: 2013,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:110
},
{
    title: "Metroid: Zero Mission",
    system_type: "GBA",
    developer: "Nintendo",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/2617564-mzm.jpg",
    description: "A GBA Metroid game and remake of the original Metroid for the NES. It also depicts what happens immediately after the self destruct sequence by Mother Brain.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    rating: 9.5,
    price: 25,
    year_released: 2004,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:111
},
{
    title: "Metroid: Zero Mission",
    system_type: "GBA",
    developer: "Nintendo",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/2617564-mzm.jpg",
    description: "A GBA Metroid game and remake of the original Metroid for the NES. It also depicts what happens immediately after the self destruct sequence by Mother Brain.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    rating: 9.5,
    price: 25,
    year_released: 2004,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:112
},{
    title: "Tetris Effect",
    system_type: "PS4",
    developer: "Enhance Games",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/3059453-image.jpeg",
    description: "Tetris for the PS4/PSVR by Tetsuya Mizuguchi, creator of REZ and Lumines.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    owned: true,
    rating: 9.5,
    price: 40,
    year_released: 2018,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:113
},
{
    title: "BioShock Infinite",
    system_type: "PS3",
    developer: "Irrational Games",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/2379677-bioshockinfinite_boxart.jpg",
    description: "The third game in the BioShock series leaves the bottom of the sea behind for an entirely new setting - the floating city of Columbia, circa 1912. Come to retrieve a girl named Elizabeth, ex-detective Booker DeWitt finds more in store for him there than he could ever imagine.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    rating: 10,
    price: 10,
    year_released: 2013,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:114
},
{
    title: "Metroid: Zero Mission",
    system_type: "GBA",
    developer: "Nintendo",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/2617564-mzm.jpg",
    description: "A GBA Metroid game and remake of the original Metroid for the NES. It also depicts what happens immediately after the self destruct sequence by Mother Brain.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    rating: 9.5,
    price: 25,
    year_released: 2004,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:115
},{
    title: "Tetris Effect",
    system_type: "PS4",
    developer: "Enhance Games",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/3059453-image.jpeg",
    description: "Tetris for the PS4/PSVR by Tetsuya Mizuguchi, creator of REZ and Lumines.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    owned: true,
    rating: 9.5,
    price: 40,
    year_released: 2018,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:116
},
{
    title: "BioShock Infinite",
    system_type: "XONE",
    developer: "Irrational Games",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/2379677-bioshockinfinite_boxart.jpg",
    description: "The third game in the BioShock series leaves the bottom of the sea behind for an entirely new setting - the floating city of Columbia, circa 1912. Come to retrieve a girl named Elizabeth, ex-detective Booker DeWitt finds more in store for him there than he could ever imagine.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    rating: 10,
    price: 10,
    year_released: 2013,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:117
},
{
    title: "Metroid: Zero Mission",
    system_type: "GBA",
    developer: "Nintendo",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/2617564-mzm.jpg",
    description: "A GBA Metroid game and remake of the original Metroid for the NES. It also depicts what happens immediately after the self destruct sequence by Mother Brain.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    rating: 9.5,
    price: 25,
    year_released: 2004,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:118
},{
    title: "Tetris Effect",
    system_type: "PS4",
    developer: "Enhance Games",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/3059453-image.jpeg",
    description: "Tetris for the PS4/PSVR by Tetsuya Mizuguchi, creator of REZ and Lumines.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    owned: true,
    rating: 9.5,
    price: 40,
    year_released: 2018,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:119
},
{
    title: "Super Smash Bros",
    system_type: "NSW",
    developer: "Irrational Games",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/2379677-bioshockinfinite_boxart.jpg",
    description: "The third game in the BioShock series leaves the bottom of the sea behind for an entirely new setting - the floating city of Columbia, circa 1912. Come to retrieve a girl named Elizabeth, ex-detective Booker DeWitt finds more in store for him there than he could ever imagine.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    rating: 10,
    price: 10,
    year_released: 2013,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:120
},
{
    title: "Xenoblade X",
    system_type: "WiiU",
    developer: "Nintendo",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/2617564-mzm.jpg",
    description: "A GBA Metroid game and remake of the original Metroid for the NES. It also depicts what happens immediately after the self destruct sequence by Mother Brain.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    rating: 9.5,
    price: 25,
    year_released: 2004,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:121
},{
    title: "Tetris Effect",
    system_type: "PS4",
    developer: "Enhance Games",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/3059453-image.jpeg",
    description: "Tetris for the PS4/PSVR by Tetsuya Mizuguchi, creator of REZ and Lumines.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    owned: true,
    rating: 9.5,
    price: 40,
    year_released: 2018,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:122,
},
{
    title: "BioShock Infinite",
    system_type: "PS3",
    developer: "Irrational Games",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/2379677-bioshockinfinite_boxart.jpg",
    description: "The third game in the BioShock series leaves the bottom of the sea behind for an entirely new setting - the floating city of Columbia, circa 1912. Come to retrieve a girl named Elizabeth, ex-detective Booker DeWitt finds more in store for him there than he could ever imagine.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    rating: 10,
    price: 10,
    year_released: 2013,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:123
},
{
    title: "Metroid: Zero Mission",
    system_type: "GBA",
    developer: "Nintendo",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/2617564-mzm.jpg",
    description: "A GBA Metroid game and remake of the original Metroid for the NES. It also depicts what happens immediately after the self destruct sequence by Mother Brain.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    rating: 9.5,
    price: 25,
    year_released: 2004,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:124
},
{
    title: "Metroid: Zero Mission",
    system_type: "GBA",
    developer: "Nintendo",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/2617564-mzm.jpg",
    description: "A GBA Metroid game and remake of the original Metroid for the NES. It also depicts what happens immediately after the self destruct sequence by Mother Brain.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    rating: 9.5,
    price: 25,
    year_released: 2004,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:125
},{
    title: "Tetris Effect",
    system_type: "PS4",
    developer: "Enhance Games",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/3059453-image.jpeg",
    description: "Tetris for the PS4/PSVR by Tetsuya Mizuguchi, creator of REZ and Lumines.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    owned: true,
    rating: 9.5,
    price: 40,
    year_released: 2018,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:126
},
{
    title: "BioShock Infinite",
    system_type: "PS3",
    developer: "Irrational Games",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/2379677-bioshockinfinite_boxart.jpg",
    description: "The third game in the BioShock series leaves the bottom of the sea behind for an entirely new setting - the floating city of Columbia, circa 1912. Come to retrieve a girl named Elizabeth, ex-detective Booker DeWitt finds more in store for him there than he could ever imagine.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    rating: 10,
    price: 10,
    year_released: 2013,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:127
},
{
    title: "Metroid: Zero Mission",
    system_type: "GBA",
    developer: "Nintendo",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/2617564-mzm.jpg",
    description: "A GBA Metroid game and remake of the original Metroid for the NES. It also depicts what happens immediately after the self destruct sequence by Mother Brain.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    rating: 9.5,
    price: 25,
    year_released: 2004,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:128
},{
    title: "Tetris Effect",
    system_type: "PS4",
    developer: "Enhance Games",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/3059453-image.jpeg",
    description: "Tetris for the PS4/PSVR by Tetsuya Mizuguchi, creator of REZ and Lumines.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    owned: true,
    rating: 9.5,
    price: 40,
    year_released: 2018,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:129
},
{
    title: "BioShock Infinite",
    system_type: "XONE",
    developer: "Irrational Games",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/2379677-bioshockinfinite_boxart.jpg",
    description: "The third game in the BioShock series leaves the bottom of the sea behind for an entirely new setting - the floating city of Columbia, circa 1912. Come to retrieve a girl named Elizabeth, ex-detective Booker DeWitt finds more in store for him there than he could ever imagine.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    rating: 10,
    price: 10,
    year_released: 2013,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:130
},
{
    title: "Metroid: Zero Mission",
    system_type: "GBA",
    developer: "Nintendo",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/2617564-mzm.jpg",
    description: "A GBA Metroid game and remake of the original Metroid for the NES. It also depicts what happens immediately after the self destruct sequence by Mother Brain.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    rating: 9.5,
    price: 25,
    year_released: 2004,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:131
},{
    title: "Tetris Effect",
    system_type: "PS4",
    developer: "Enhance Games",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/3059453-image.jpeg",
    description: "Tetris for the PS4/PSVR by Tetsuya Mizuguchi, creator of REZ and Lumines.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    owned: true,
    rating: 9.5,
    price: 40,
    year_released: 2018,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:132
},
{
    title: "Super Smash Bros",
    system_type: "NSW",
    developer: "Irrational Games",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/2379677-bioshockinfinite_boxart.jpg",
    description: "The third game in the BioShock series leaves the bottom of the sea behind for an entirely new setting - the floating city of Columbia, circa 1912. Come to retrieve a girl named Elizabeth, ex-detective Booker DeWitt finds more in store for him there than he could ever imagine.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    rating: 10,
    price: 10,
    year_released: 2013,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:133
},
{
    title: "Xenoblade X",
    system_type: "WiiU",
    developer: "Nintendo",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/2617564-mzm.jpg",
    description: "A GBA Metroid game and remake of the original Metroid for the NES. It also depicts what happens immediately after the self destruct sequence by Mother Brain.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    rating: 9.5,
    price: 25,
    year_released: 2004,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:134
},{
    title: "Tetris Effect",
    system_type: "PS4",
    developer: "Enhance Games",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/3059453-image.jpeg",
    description: "Tetris for the PS4/PSVR by Tetsuya Mizuguchi, creator of REZ and Lumines.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    owned: true,
    rating: 9.5,
    price: 40,
    year_released: 2018,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:135,
},
{
    title: "BioShock Infinite",
    system_type: "PS3",
    developer: "Irrational Games",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/2379677-bioshockinfinite_boxart.jpg",
    description: "The third game in the BioShock series leaves the bottom of the sea behind for an entirely new setting - the floating city of Columbia, circa 1912. Come to retrieve a girl named Elizabeth, ex-detective Booker DeWitt finds more in store for him there than he could ever imagine.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    rating: 10,
    price: 10,
    year_released: 2013,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:136
},
{
    title: "Metroid: Zero Mission",
    system_type: "GBA",
    developer: "Nintendo",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/2617564-mzm.jpg",
    description: "A GBA Metroid game and remake of the original Metroid for the NES. It also depicts what happens immediately after the self destruct sequence by Mother Brain.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    rating: 9.5,
    price: 25,
    year_released: 2004,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:137
},
{
    title: "Metroid: Zero Mission",
    system_type: "GBA",
    developer: "Nintendo",
    box_art: "https://www.giantbomb.com/api/image/scale_medium/2617564-mzm.jpg",
    description: "A GBA Metroid game and remake of the original Metroid for the NES. It also depicts what happens immediately after the self destruct sequence by Mother Brain.",
    is_beaten: true,
    favorite: true,
    now_playing: false,
    rating: 9.5,
    price: 25,
    year_released: 2004,
    date: new Date(Date.now()),
    user: "Justin",
    note: "testing",
    _id:138
}
]

export default gameSeed